<template>
    <div id="questionnaire">
        <div class="header">
            <img src="../../assets/questionnaire/headerbg.png" alt="" />
            <img class="animationImg" src="../../assets/questionnaire/sectionRightIcon.png" alt="">
            <img class="animationImg" src="../../assets/questionnaire/sectionRightIcon.png" alt="">
            <img class="animationImg" src="../../assets/questionnaire/sectionRightIcon.png" alt="">
            <img class="animationImg" src="../../assets/questionnaire/sectionRightIcon.png" alt="">
            <img class="animationImg" src="../../assets/questionnaire/sectionRightIcon.png" alt="">
            <img class="animationImg" src="../../assets/questionnaire/sectionRightIcon.png" alt="">
        </div>
        <div class="section">
            <div class="section_leftIcon">
                <img src="../../assets/questionnaire/sectionLeftIcn.png" alt="" />
            </div>
            <div class="section_title">
                您的意见建议对我们非常重要哦~
            </div>
            <div class="section_list">
                <div class="section_list_item" v-for="(item,index) in list" :key='index'>
                    <h3>
                        {{item.title}}
                        <small v-if="item.type==1">多选题</small>
                    </h3>
                    <div class="list_item_option" v-if="item.type==1">
                        <div class="list_item_checkInput" v-for="(child,i) in item.option" :key="i">
                            <input type="checkbox" :name="'check'+index" :value="child" v-model="item.select" :id="index+'id'+i"  @change="eventChanage"/>
                            <label :for="index+'id'+i">
                                <span class="checkboxCss" ></span>
                                {{child}}
                            </label>
                        </div>
                    </div>
                    <div class="list_item_option" v-else>
                        <div class="list_item_checkInput" v-for="(child,i) in item.option" :key="i">
                            <input type="radio" :name="'check'+index" :value="child"  v-model="item.select" :id="index+'id'+i"  @change="eventChanage" />
                            <label :for="index+'id'+i">
                                <span class="checkboxCss" ></span>
                                {{child}}
                            </label>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="section_footer">
            <div class="footer_sumbit" v-if="!inputComfim" >
                您还没有完成问卷噢~
            </div>
            <div class="footer_sumbit active" v-else  @click="submit">
                提交问卷领取会员权益
            </div>
        </div>
    </div>
</template>
<script>
import API from '@/utils/request.js';
export default {
    name:'questionnaire',
    data(){
        return{
            disable:false,
            list:[
                {title:'1. 您孩子的年龄是？',type:1,option:['3~5岁','5~12岁','12岁以上'],select:[]},
                {title:'2. 您的孩子是否使用过线上教育APP？',type:2,option:['是','否'],select:''},
                {title:'3. 您是通过什么途径了解到小新课堂App的？',type:1,option:['朋友介绍','电视广播','老师推荐','报纸','微博','传单','其他'],select:[]},
                {title:'4. 您愿意孩子使用什么设备上网学习？',type:2,option:['手机','平板','电脑','电视','其他'],select:''},
                {title:'5. 选择教育类app时您最看中什么？',type:1,option:['学习内容','价格','口碑','服务','其他'],select:[]},
                {title:'6. 您了解孩子每科的薄弱点在哪里吗？',type:2,option:['了如指掌','不甚了解','不在意'],select:''},
                {title:'7. 您希望了解孩子使用小新课堂APP后的学习掌握情况吗？',type:2,option:['希望如此','偶尔了解','不在意'],select:''},
                {title:'8. 您了解小新课堂APP的课程顾问老师这个功能模块吗？',type:2,option:['了解','不了解','不在意'],select:''},
                {title:'9. 您觉得小新课堂APP还需要改进的地方是什么？',type:1,option:['增加学习内容','增加学习闯关部分','增加课程老师服务答疑','降低会员价格','增加同步习题或试卷','无'],select:[]},
            ],
            inputComfim:false,
        }
    },
    mounted(){
        localStorage.setItem('token','')
    },
    activated(){
        localStorage.setItem('token','')
    },
    methods:{
        submit(){
            if(this.disable) return false;
            this.disable = true;
            setTimeout(() => {
                this.disable = false;
            }, 2000);
            let options = '';
            for(let i in this.list){
                let item = this.list[i]
                if(i!=0) options+=','
                if(item.type==1){
                    options += item.select.join('|')
                }
                else{
                    options += item.select
                }
            }
            let formData = new FormData();
            formData.append('answer',options)
            API.commitAnswer(formData).then(res=>{
                if(res.code==200){
                    this.$store.state.submit = true;
                    setTimeout(() => {
                        this.$router.push({path:'/getMeber'})
                    }, 100);
                    
                }
            })
        },
        eventChanage(){
            this.inputComfim = this.list.every(item=>{
                return item.select.length>0
            })
        },
    }
}
</script>
<style lang="scss">
body,html{
    min-width: auto;
}
@function s($num) {
	@return $num / (3.75) +vw
}
#questionnaire{
    width: 100%;
    background: #F9FAFE;
    font-family: SourceHanSansCN-Regularn;
    
    .header{
        position: relative;
        width:100%;
        height: s(248);
        background: linear-gradient(180deg, rgba(61, 181, 255, 0) 0%, rgba(115, 201, 255, 0.43) 71.01%, rgba(102, 181, 255, 0.72) 100%);
        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
        }
        .animationImg{
            position: absolute;
            top: 20%;
            left: -30%;
            width: s(68);
            height: s(52);
            transform:rotateX(82deg) scale(1.2);
            animation: toRight 12s linear infinite;
            z-index: 1;
        }
        .animationImg:nth-of-type(2){
            top: 2%;
            transform:scale(1.5)  rotateX(74deg);
            animation: toRight 15s linear 2s infinite;
            // z-index: 3;
        }
        .animationImg:nth-of-type(3){
            top: 50%;
            transform:rotateX(82deg)  scale(1.5);
            animation: toRight 15s linear 4s infinite;
        }
        .animationImg:nth-of-type(4){
            top: 30%;
            transform:  rotateX(82deg) scale(2);
            animation: toRight 15s linear 7s infinite;
        }
        .animationImg:nth-of-type(5){
            top: 70%;
            transform:  rotateX(82deg) scale(2.3);
            animation: toRight 15s linear 10s infinite;
        }
        .animationImg:nth-of-type(6){
            top: 40%;
            transform:  rotateX(82deg) scale(2.3);
            animation: toRight 15s linear 5s infinite;
        }
    }
    .section{
        width: s(343);
        margin: s(-13) auto 0;
        background: #fff;
        box-shadow: s(2) s(2) s(12) rgba(102, 179, 255, 0.08);
        border-radius: s(16);
        position: relative;
        &_leftIcon{
            position: absolute;
            top: s(-35);
            left: s(-12);
            width: s(77);
            height: s(58);
            z-index: 2;
            img{
                width: 100%;
                height: 100%;
            }
        }
        &_title{
            font-size: 12px;
            color: #707070;
            width: 100%;
            padding: s(31) 0 s(17);
            text-indent: s(20);
        }
        &_list{
            width: 100%;
            padding: 0 s(12) s(48) s(20);
            box-sizing: border-box;
            &_item{
                width: 100%;
                margin-bottom: s(25);
                h3{
                    color: rgb(0, 0, 0);
                    font-size: s(18);
                    padding-bottom: s(15);
                    small{
                        color: #A6A6A6;
                        font-size: s(14);
                        margin-left: s(2);
                    }
                }
            }
            .list_item_option{
                width: 100%;
                .list_item_checkInput{
                    width: 100%;
                    // margin-top: s(10);
                    padding: s(15) 0;
                    display: flex;
                    align-items: center;
                    input{
                        visibility: hidden;
                    }
                    label{
                        width: 100%;
                        color: rgb(56, 56, 56);
                        font-size: s(18);
                        display: flex;
                        align-items: center;
                        .checkboxCss{
                            display: block;
                            width: s(10);
                            height: s(10);
                            margin-left: s(-10);
                            margin-right: s(20);
                            background: #E5E5E5;
                            border-radius: 50%;
                        }
                    }
                    input:checked + label .checkboxCss{
                        transition: all 0.3s ease;
                        background: linear-gradient(210.04deg, rgba(84, 152, 255, 1) 0%, rgba(84, 192, 255, 1) 100%);
                        box-shadow: s(2) s(2) s(24) rgba(84, 161, 255, 0.32);
                    }
                }
            }
        }
    }
    .section_footer{
        width: 100%;
        padding-bottom: s(50);
        .footer_sumbit{
            width: s(300);
            height: s(48);
            margin: s(40) auto 0;
            background-color: rgba(229, 229, 229, 1);
            border-radius: s(25);
            text-align: center;
            line-height: s(48);
            color: rgba(255, 255, 255, 1);
            font-size: s(18);
            transition: all 0.1s ease;
            font-weight: bold;
        }
        .active{
            box-shadow: 0px s(2) s(4) rgba(60, 132, 240, 0.59);
            background: linear-gradient(181.29deg, rgba(84, 152, 255, 1) 0%, rgba(84, 192, 255, 1) 100%);
            box-shadow: s(2) s(2) s(24) rgba(84, 161, 255, 0.32);
        }
    }
}
@keyframes toRight {
    0%{left:-30%}
    100%{left: 120%;}
}
</style>